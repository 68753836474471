/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BankListResponse } from '../models';
// @ts-ignore
import { DepartmentListResponse } from '../models';
// @ts-ignore
import { LegalFormListResponse } from '../models';
// @ts-ignore
import { ResponsibilityListResponse } from '../models';
// @ts-ignore
import { UserRoleListResponse } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bank list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banks: async (ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Departments list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departments: async (ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Legal forms list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legalFormsList: async (ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legal-forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OpenAPI json file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiJson: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openapi.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Responsibilities list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        responsibilities: async (ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/responsibilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User role list
         * @param {string} contractUid 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userrolesList: async (contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('userrolesList', 'contractUid', contractUid)
            const localVarPath = `/api/user-roles/{contractUid}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (employeeUid !== undefined) {
                localVarQueryParameter['employeeUid'] = employeeUid;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bank list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banks(ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banks(ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Departments list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async departments(ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.departments(ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Legal forms list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async legalFormsList(ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalFormListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.legalFormsList(ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OpenAPI json file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openapiJson(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openapiJson(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Responsibilities list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async responsibilities(ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsibilityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.responsibilities(ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User role list
         * @param {string} contractUid 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userrolesList(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userrolesList(contractUid, ngmCountry, employeeUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Bank list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banks(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<BankListResponse> {
            return localVarFp.banks(ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Departments list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departments(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<DepartmentListResponse> {
            return localVarFp.departments(ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Legal forms list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legalFormsList(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LegalFormListResponse> {
            return localVarFp.legalFormsList(ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OpenAPI json file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiJson(options?: any): AxiosPromise<void> {
            return localVarFp.openapiJson(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Responsibilities list
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        responsibilities(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ResponsibilityListResponse> {
            return localVarFp.responsibilities(ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User role list
         * @param {string} contractUid 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userrolesList(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, options?: any): AxiosPromise<UserRoleListResponse> {
            return localVarFp.userrolesList(contractUid, ngmCountry, employeeUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Bank list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    banks(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<BankListResponse>;

    /**
     * 
     * @summary Departments list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    departments(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<DepartmentListResponse>;

    /**
     * 
     * @summary Legal forms list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    legalFormsList(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<LegalFormListResponse>;

    /**
     * 
     * @summary OpenAPI json file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    openapiJson(options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Responsibilities list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    responsibilities(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ResponsibilityListResponse>;

    /**
     * 
     * @summary User role list
     * @param {string} contractUid 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [employeeUid] Employee &#x60;uid&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    userrolesList(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, options?: any): AxiosPromise<UserRoleListResponse>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Bank list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public banks(ngmCountry?: 'cz' | 'be', options?: any) {
        return DefaultApiFp(this.configuration).banks(ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Departments list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public departments(ngmCountry?: 'cz' | 'be', options?: any) {
        return DefaultApiFp(this.configuration).departments(ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Legal forms list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public legalFormsList(ngmCountry?: 'cz' | 'be', options?: any) {
        return DefaultApiFp(this.configuration).legalFormsList(ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OpenAPI json file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public openapiJson(options?: any) {
        return DefaultApiFp(this.configuration).openapiJson(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Responsibilities list
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public responsibilities(ngmCountry?: 'cz' | 'be', options?: any) {
        return DefaultApiFp(this.configuration).responsibilities(ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User role list
     * @param {string} contractUid 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [employeeUid] Employee &#x60;uid&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userrolesList(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, options?: any) {
        return DefaultApiFp(this.configuration).userrolesList(contractUid, ngmCountry, employeeUid, options).then((request) => request(this.axios, this.basePath));
    }
}
